import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'

const ResourcesPage = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <div className="border-b border-gray-100">
        <div className="main-container">
          <div className="welcome text-center mt-10 sm:mt-24 md:mt-32 sm:mx-10 md:mx-20 mb-20">
            <h1 className="text-4xl sm:text-6xl font-bold font-display uppercase">This is the resources page</h1>
            <p className="mt-5 sm:mt-10 text-lg uppercase">This is the content for the resources page.</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ResourcesPage
